import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { ArrowUpToLine, Edit2, RefreshCcw, Search, X } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { editDevice } from '../../../api/edit-device'
import { excludeDevice } from '../../../api/exclude-device'
import { getDevices, GetDevicesResponse } from '../../../api/get-devices'
import { getRoles } from '../../../api/get-roles'
import { getTransmissors } from '../../../api/get-transmissors'
import { provideDevice } from '../../../api/provide-device'
import { registerDevices } from '../../../api/register-devices'
import { synchronizeDevice } from '../../../api/synchronize-device'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/Form/Checkbox'
import * as Input from '../../../components/Form/Input'
import * as Select from '../../../components/Form/Select'
import { Textarea } from '../../../components/Form/Textarea'
import { Pagination } from '../../../components/Pagination'
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
} from '../../../components/Table'
import { Toast } from '../../../components/Toast'
import { Tooltip } from '../../../components/Tooltip'
import { queryClient } from '../../../lib/react-query'
import { vlans } from '../../../lib/vlans'
import { DevicesTableSkeleton } from './devices-table-skeleton'
import { SelectableDeviceRow } from './selectable-device-row'

const editdeviceSchema = z.object({
  transmissor: z.string(),
  slot: z.string(),
  pon: z.string(),
  cto: z.string().min(8),
})

type EditDeviceSchema = z.infer<typeof editdeviceSchema>

export function IXC() {
  const navigate = useNavigate()

  const [allChecked, setAllChecked] = useState(false)
  const [lastChecked, setLastChecked] = useState<string | null>(null)
  const [hoverChecked, setHoverChecked] = useState<string | null>(null)
  const [devices, setDevices] = useState<string[]>([])

  const [isHoldingShift, setIsHoldingShift] = useState(false)

  useEffect(() => {
    document.body.onkeyup = (e) => {
      if (e.key === 'Shift') {
        setIsHoldingShift(false)
      }
    }
    document.body.onkeydown = (e) => {
      if (e.key === 'Shift') {
        setIsHoldingShift(true)
      }
    }
  }, [])

  const [rows, setRows] = useState('')

  const { data: roles } = useQuery({
    queryKey: ['roles'],
    queryFn: getRoles,
  })

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<EditDeviceSchema>({
    resolver: zodResolver(editdeviceSchema),
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const perPage = z.coerce.number().parse(searchParams.get('amount') ?? '16')

  const pageIndex = z.coerce
    .number()
    .transform((page) => page - 1)
    .parse(searchParams.get('page') ?? '1')

  function handlePaginate(pageIndex: number) {
    setSearchParams((state) => {
      state.set('page', (pageIndex + 1).toString())

      return state
    })
  }

  const { data: result, isLoading } = useQuery({
    queryKey: ['get-devices', pageIndex, perPage],
    queryFn: () =>
      getDevices({
        pageIndex,
        perPage,
      }),
    refetchOnWindowFocus: false,
  })

  const { data: transmissorsResult } = useQuery({
    queryKey: ['get-transmissors'],
    queryFn: getTransmissors,
    refetchOnWindowFocus: false,
  })

  const { mutateAsync: excludeDeviceFn, isPending: isExcludePending } =
    useMutation({
      mutationKey: ['exclude-devices'],
      mutationFn: excludeDevice,
      onSuccess: async (_, variables) => {
        updateDevice(variables.deviceId, {
          status: {
            name: 'ONU desprovisionada',
            type: 'success',
          },
        })
      },
      onError: async (_, variables) => {
        updateDevice(variables.deviceId, {
          status: {
            name: 'ONU não está provisionada',
            type: 'error',
          },
        })
      },
    })

  const { mutateAsync: provideDeviceFn, isPending: isProvidePending } =
    useMutation({
      mutationKey: ['provide-devices'],
      mutationFn: provideDevice,
      onSuccess: async (_, variables) => {
        updateDevice(variables.deviceId, {
          status: {
            name: 'ONU provisionada',
            type: 'success',
          },
        })
      },
      onError: async (_, variables) => {
        updateDevice(variables.deviceId, {
          status: {
            name: 'Erro ao provisionar ONU',
            type: 'error',
          },
        })
      },
    })

  const { mutateAsync: editDeviceFn, isPending: isEditPending } = useMutation({
    mutationKey: ['migrate-devices'],
    mutationFn: editDevice,
    onSuccess: async (data, variables) => {
      updateDevice(variables.deviceId, {
        ...data,
        status: {
          name: 'Linha fibra editada',
          type: 'success',
        },
      })
    },
    onError: async (
      error: {
        response: {
          data: { message: string; deviceId: string; toastMessage: string }
        }
      },
      variables,
    ) => {
      updateDevice(variables.deviceId, {
        status: {
          name: error.response.data.message,
          type: 'error',
        },
      })
      toast.custom((t) => (
        <Toast
          t={t}
          title={`ID ${error.response.data.deviceId}: ${error.response.data.toastMessage}`}
          variant="error"
        />
      ))
    },
  })

  const { mutateAsync: synchronizeDeviceFn, isPending: isSynchronizePending } =
    useMutation({
      mutationKey: ['synchronize-devices'],
      mutationFn: synchronizeDevice,
      onSuccess: async (data, variables) => {
        updateDevice(variables.deviceId, {
          ...data,
          status: {
            name: 'Sincronizado',
            type: 'success',
          },
        })
      },
      onError: async (
        error: { response: { data: { message: string; deviceId: string } } },
        variables,
      ) => {
        updateDevice(variables.deviceId, {
          status: {
            name: 'Erro ao sincronizar',
            type: 'error',
          },
        })
        toast.custom((t) => (
          <Toast
            t={t}
            title={`${error.response.data.deviceId}: ${error.response.data.message}`}
            variant="error"
          />
        ))
      },
    })

  const { mutateAsync: registerDevicesFn, isPending: isRegisteringDevices } =
    useMutation({
      mutationKey: ['register-devices'],
      mutationFn: registerDevices,
      onSuccess: async (data) => {
        queryClient.refetchQueries({ queryKey: ['get-devices'] })
        setRows('')

        if (data.success[0]) {
          for (const success of data.success) {
            toast.custom((t) => (
              <Toast
                t={t}
                title={`${success.mac}: Dispositivo registrado com sucesso!`}
                variant="success"
              />
            ))
          }
        }

        if (data.errors[0]) {
          for (const error of data.errors) {
            toast.custom((t) => (
              <Toast
                t={t}
                title={`${error.mac}: ${error.message}`}
                variant="error"
              />
            ))
          }
        }
      },
    })

  function updateDevice(
    deviceId: string,
    newDevice: Partial<GetDevicesResponse['devices'][number]>,
  ) {
    const devicesListingCache = queryClient.getQueriesData<GetDevicesResponse>({
      queryKey: ['get-devices'],
    })

    devicesListingCache.forEach(([cacheKey, cached]) => {
      if (!cached) {
        return
      }

      queryClient.setQueryData<GetDevicesResponse>(cacheKey, {
        ...cached,
        devices: cached.devices.map((originalDevice) => {
          if (originalDevice.id !== deviceId) {
            return originalDevice
          }

          return {
            ...originalDevice,
            ...newDevice,
          }
        }),
      })
    })
  }

  function handleEditDevices(data: EditDeviceSchema) {
    const { transmissor, slot, pon, cto } = data

    const vlan = vlans.find((vlan) => vlan.transmissorId === transmissor)?.vlan

    if (!vlan) return

    for (const device of devices) {
      updateDevice(device, {
        status: {
          name: 'Editando linha fibra',
          type: 'progress',
        },
      })
      editDeviceFn({
        transmissor,
        slot,
        pon,
        cto,
        vlan,
        deviceId: device,
      })
    }
  }

  function handleInsertDevices() {
    const lines = rows.split('\n')

    const mac = lines.map((line) => line.split('  ')[4]).filter(Boolean)

    registerDevicesFn({
      devices: mac,
    })
  }

  function handleSynchronizeDevice() {
    for (const device of devices) {
      updateDevice(device, {
        status: {
          name: 'Sincronizando',
          type: 'progress',
        },
      })
      synchronizeDeviceFn({
        deviceId: device,
      })
    }
  }

  function handleExcludeDevice() {
    for (const device of devices) {
      updateDevice(device, {
        status: {
          name: 'Desprovisionando',
          type: 'progress',
        },
      })
      excludeDeviceFn({
        deviceId: device,
      })
    }
  }

  function handleProvideDevice() {
    for (const device of devices) {
      updateDevice(device, {
        status: {
          name: 'Provisionando',
          type: 'progress',
        },
      })
      provideDeviceFn({
        deviceId: device,
      })
    }
  }

  const isDoingSomeAction = result?.devices.some((device) => device.status)

  const isEditingPending =
    !devices.length ||
    !isValid ||
    isEditPending ||
    isExcludePending ||
    isProvidePending

  const isExcludeDevicePending =
    !devices.length || isEditPending || isExcludePending || isProvidePending

  const isProvideDevicePending =
    !devices.length || isProvidePending || isExcludePending || isEditPending

  useEffect(() => {
    if (!result) return

    if (allChecked) {
      setDevices(result.devices.map((device) => device.id))
    } else {
      setDevices([])
    }
  }, [allChecked])

  if (roles) {
    const hasPermissions = roles.teams.some((team) =>
      team.permissions.some(
        (permission) => permission.name === 'integrationIXC',
      ),
    )

    if (!hasPermissions) {
      navigate('/')
      return null
    }
  }

  return (
    <>
      <div className="gap-8 md:grid md:grid-cols-[1fr_24px_1fr]">
        <div className="px-4 md:px-0 md:py-8">
          <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
            Origem
          </h1>
          <div className="mt-6 flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
              MACs
            </label>
            <Textarea
              value={rows}
              onChange={(e) => setRows(e.target.value)}
              placeholder="Insira os MACs a serem migrados."
            />
            <div className="mt-4 flex justify-between gap-3">
              <Tooltip description="Sincrozinar informações da linha selecionada com IXCSoft">
                <Button
                  onClick={handleSynchronizeDevice}
                  disabled={isSynchronizePending || !devices.length}
                  variant="outline"
                  className="flex items-center justify-center gap-1.5"
                >
                  <RefreshCcw className="h-5 w-5" /> Sincronizar
                </Button>
              </Tooltip>
              <Button
                onClick={handleInsertDevices}
                disabled={isRegisteringDevices || !rows}
                className="flex items-center justify-center gap-1.5"
              >
                <ArrowUpToLine className="h-5 w-5" /> Inserir
              </Button>
            </div>
          </div>
        </div>
        <div className="px-4 md:flex md:justify-center md:px-0">
          <div className="my-8 h-px w-full bg-gray-100 dark:bg-gray-750 md:my-0 md:h-full md:w-px" />
        </div>
        <form
          onSubmit={handleSubmit(handleEditDevices)}
          className="flex flex-col px-4 md:px-0 md:py-8"
        >
          <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-25">
            Destino
          </h1>
          <div className="mt-6 gap-4 md:grid md:grid-cols-2">
            <div className="flex flex-col gap-1.5 md:col-span-2">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                Transmissor*
              </label>
              <Controller
                name="transmissor"
                control={control}
                render={({ field }) => (
                  <Select.Root
                    {...field}
                    value={field.value}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger>
                      <Select.Value placeholder="Selecione o transmissor" />
                    </Select.Trigger>

                    <Select.Content>
                      {transmissorsResult &&
                        transmissorsResult.transmissors.map(
                          ({ id, descricao }) => (
                            <Select.Item key={id} value={id}>
                              <Select.ItemText>{descricao}</Select.ItemText>
                            </Select.Item>
                          ),
                        )}
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </div>
            <div className="flex flex-col gap-1.5">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                SLOT*
              </label>
              <Controller
                name="slot"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control {...field} placeholder="0-99" />
                  </Input.Root>
                )}
              />
            </div>
            <div className="mt-6 flex flex-col gap-1.5 md:mt-0">
              <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
                PON*
              </label>
              <Controller
                name="pon"
                control={control}
                render={({ field }) => (
                  <Input.Root>
                    <Input.Control {...field} placeholder="0-99" />
                  </Input.Root>
                )}
              />
            </div>
          </div>
          <div className="mt-6 flex flex-col gap-1.5">
            <label className="text-sm font-medium text-gray-700 dark:text-gray-375">
              CTO*
            </label>
            <Controller
              name="cto"
              control={control}
              render={({ field }) => (
                <Input.Root>
                  <Input.Control {...field} placeholder="IDN-00/00" />
                </Input.Root>
              )}
            />
          </div>
          <div className="mt-6 flex justify-between gap-3">
            <div className="flex gap-3">
              <Button
                type="button"
                variant="outline"
                onClick={handleExcludeDevice}
                className="flex items-center justify-center gap-1.5"
                disabled={isExcludeDevicePending}
              >
                <X className="h-5 w-5" /> Desprovisionar
              </Button>
              <Button
                type="button"
                variant="outline"
                onClick={handleProvideDevice}
                className="flex items-center justify-center gap-1.5"
                disabled={isProvideDevicePending}
              >
                <ArrowUpToLine className="h-5 w-5" />
                Provisionar
              </Button>
            </div>
            <Button
              type="submit"
              className="flex items-center justify-center gap-1.5"
              disabled={isEditingPending}
            >
              <Edit2 className="h-5 w-5" /> Editar
            </Button>
          </div>
        </form>
      </div>
      <Table className="mt-6">
        <TableHeader>
          <TableHead>
            <div className="flex items-center gap-3">
              <Checkbox
                id="all"
                disabled={!result?.devices.length}
                checked={allChecked}
                onCheckedChange={(checked: boolean) => setAllChecked(checked)}
              />
              <span className="leading-tight">ID</span>
            </div>
          </TableHead>
          <TableHead className="leading-tight">Transmissor</TableHead>
          <TableHead className="leading-tight">SLOT</TableHead>
          <TableHead className="leading-tight">PON</TableHead>
          <TableHead className="leading-tight">Nome</TableHead>
          <TableHead className="leading-tight">MAC</TableHead>
          <TableHead className="leading-tight">Inserido por</TableHead>
          <TableHead>{isDoingSomeAction && 'Status'}</TableHead>
        </TableHeader>
        <TableBody>
          {result &&
            result.devices.map((device) => (
              <SelectableDeviceRow
                key={device.id}
                device={device}
                allChecked={allChecked}
                devices={result}
                selectedDevices={devices}
                setDevices={setDevices}
                lastChecked={lastChecked}
                setLastChecked={setLastChecked}
                hoverChecked={hoverChecked}
                setHoverChecked={setHoverChecked}
                isHoldingShift={isHoldingShift}
              />
            ))}
          {isLoading && <DevicesTableSkeleton perPage={8} />}
        </TableBody>
      </Table>
      {_.isEmpty(result?.devices) && !isLoading && (
        <div className="flex w-full flex-col items-center justify-center border-x border-b border-gray-100 px-8 py-10 pb-12 dark:border-gray-750">
          <div className="rounded-lg border border-gray-100 p-3 shadow-xs dark:border-gray-750">
            <Search className="h-6 w-6 text-gray-700 dark:text-gray-100" />
          </div>
          <h1 className="mt-4 font-semibold text-gray-900 dark:text-gray-25">
            Nenhum dispositivo encontrado
          </h1>
          <p className="mt-1 text-center text-sm text-gray-600 dark:text-gray-375">
            Sua pesquisa não encontrou nenhum dispositivo. <br />
          </p>
        </div>
      )}
      {result && (
        <Pagination
          onPageChange={handlePaginate}
          pageIndex={result.meta.pageIndex}
          totalCount={result.meta.totalCount}
          totalInPage={result.devices.length}
          perPage={result.meta.perPage}
        />
      )}
    </>
  )
}
