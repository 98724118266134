import { useEffect, useState } from 'react'

import { GetDevicesResponse } from '../../../api/get-devices'
import { Checkbox } from '../../../components/Form/Checkbox'
import { TableCell, TableRow } from '../../../components/Table'
import { Tag } from '../../../components/Tag'

type Device = GetDevicesResponse['devices'][number]

interface DeviceProps extends Device {
  status?: {
    name: string
    type: 'progress' | 'error' | 'success'
  }
}

interface SelectableDeviceRowProps {
  device: DeviceProps
  lastChecked: string | null
  setLastChecked: React.Dispatch<React.SetStateAction<string | null>>
  hoverChecked: string | null
  setHoverChecked: React.Dispatch<React.SetStateAction<string | null>>
  allChecked: boolean
  devices: GetDevicesResponse
  selectedDevices: string[]
  setDevices: React.Dispatch<React.SetStateAction<string[]>>
  isHoldingShift: boolean
}

export function SelectableDeviceRow({
  device,
  allChecked,
  devices,
  selectedDevices,
  setDevices,
  lastChecked,
  setLastChecked,
  hoverChecked,
  setHoverChecked,
  isHoldingShift,
}: SelectableDeviceRowProps) {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(allChecked)
  }, [allChecked])

  useEffect(() => {
    if (selectedDevices.length === 0) {
      setLastChecked(null)
    }

    if (selectedDevices.some((item) => item === device.id)) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [selectedDevices])

  return (
    <TableRow key={device.id}>
      <TableCell data-state={checked}>
        <div className="flex items-center gap-3">
          <Checkbox
            id={device.id}
            checked={checked}
            onMouseEnter={() => {
              setHoverChecked(device.id)
            }}
            onCheckedChange={(checked: boolean) => {
              if (checked) {
                if (!lastChecked) {
                  setLastChecked(device.id)
                }

                if (isHoldingShift) {
                  if (lastChecked) {
                    const lastCheckedIndex = devices.devices.findIndex(
                      (item) => item.id === lastChecked,
                    )
                    const hoverCheckedIndex = devices.devices.findIndex(
                      (item) => item.id === hoverChecked,
                    )

                    if (lastCheckedIndex > hoverCheckedIndex) {
                      setDevices(
                        devices.devices
                          .slice(hoverCheckedIndex, lastCheckedIndex + 1)
                          .map((item) => item.id),
                      )
                    } else {
                      setDevices(
                        devices.devices
                          .slice(lastCheckedIndex, hoverCheckedIndex)
                          .map((item) => item.id),
                      )
                    }
                  }
                }

                setDevices((state) => [...state, device.id])
              } else {
                setDevices((state) =>
                  state.filter((item) => item !== device.id),
                )
                setLastChecked(device.id)
              }
              setChecked(checked)
            }}
          />
          <span>{device.id}</span>
        </div>
      </TableCell>
      <TableCell data-state={checked}>{device.transmissor}</TableCell>
      <TableCell data-state={checked}>{device.slot}</TableCell>
      <TableCell data-state={checked}>{device.pon}</TableCell>
      <TableCell data-state={checked}>{device.name}</TableCell>
      <TableCell data-state={checked}>{device.mac}</TableCell>
      <TableCell data-state={checked}>{device.createdBy.name}</TableCell>
      <TableCell data-state={checked}>
        {device.status && (
          <Tag
            className="flex items-center"
            variant={
              device.status.type === 'progress'
                ? 'yellow'
                : device.status.type === 'success'
                  ? 'green'
                  : device.status.type === 'error'
                    ? 'red'
                    : 'purple'
            }
          >
            {device.status.name}
          </Tag>
        )}
      </TableCell>
    </TableRow>
  )
}
